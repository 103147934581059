/* global require $ */
require('./main.scss');

require('./image/logo.svg');
require('./image/logo-icon.svg');
require('./image/logo-purple.svg');
require('./image/laptop.png');

require('./image/automatic-plans.png');
// require('./image/incorporate.png');
// require('./image/instant-planning.png');

require('./image/incorporate.webp');
require('./image/instant-planning.webp');

//require('./image/big-obstacle.png');
require('./image/big-obstacle.webp');
// require('./image/capture-those-leads.png');
require('./image/capture-those-leads.webp');
// require('./image/frictionless.png');
require('./image/frictionless.webp');
// require('./image/labour.png');
// require('./image/no-training.png');
require('./image/no-training.webp');
// require('./image/quoting-yourself.png');
require('./image/quoting-yourself.webp');
// require('./image/time-is-money.png');
require('./image/time-is-money.webp');
// require('./image/your-planner.png');
require('./image/your-planner.webp');

// require('./image/detailed-banner.png');
require('./image/detailed-banner.webp');
// require('./image/blogs-banner.jpg');
require('./image/blogs-banner.webp');

// require('./image/planning-banner.png');
require('./image/planning-banner.webp');
// require('./image/automation-banner.png');
require('./image/automation-banner.webp');
// require('./image/incorporate-banner.png');
require('./image/incorporate-banner.webp');

require('./image/banner.mp4');
require('./image/poster.jpg');

// Favicons
require('./image/favicon/apple-touch-icon.png');
require('./image/favicon/favicon-32x32.png');
require('./image/favicon/favicon-16x16.png');
require('./image/favicon/site.webmanifest');
require('./image/favicon/safari-pinned-tab.svg');
require('./image/favicon/mstile-150x150.png');

//Social
require('./image/social/facebook.svg');
require('./image/social/instagram.svg');
require('./image/social/linkedin.svg');

// Blogs
// require('./image/blog/critical-importance-of-handrail-system.jpg');
// require('./image/blog/5-ways-our-3d-planner-will-boost-your-business.jpg');
// require('./image/blog/3D-planner.jpg');
// require('./image/blog/time-management.jpg');

require('./image/blog/critical-importance-of-handrail-system.webp');
require('./image/blog/5-ways-our-3d-planner-will-boost-your-business.webp');
require('./image/blog/3D-planner.webp');
require('./image/blog/time-management.webp');

const $ = (window.jQuery = require('jquery'));

fancyLabels = function (sel) {
	$(sel).focus(function () {
		$(this).parent('.row').addClass('move-label');
	});

	$(sel).blur(function () {
		if (!$(this).val()) {
			$(this).parent('.row').removeClass('move-label');
		}
	});
};

contactForm = function (sel) {
	$(sel).submit(function (e) {
		var me = this;
		var $me = $(me);
		e.preventDefault();

		$me.find('input, select, textarea').removeClass('error');
		$.post(me.action, $me.serializeArray(), function (data) {
			if (data.success) {
				if (window.gtag) {
					window.gtag('event', 'submit', {
						event_category: 'Form',
						event_label: window.location.pathname,
					});
				}
				me.reset();
				$me.find('.alert').html(data.message);
				return;
			}
			for (var key in data.errors) {
				$me.find('[name=' + key + ']').addClass('error');
			}
		});
	});
};

revealFooter = function (sel) {
	if ($(window).height() > $(sel).outerHeight()) {
		$footerHeight = $(sel).outerHeight();
		$prev = $(sel).prev();
		$prev.css('margin-bottom', $footerHeight);
		if (!$(sel).hasClass('fixed-footer')) {
			$(sel).addClass('fixed-footer');
		}
	}
};

gaTriggers = function (sel) {
	$(sel).click(function () {
		gtag('event', 'click', {
			event_category: $(this).data('ga-category'),
			event_label: $(this).data('ga-label'),
		});
	});
};

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

$(function () {
	revealFooter('footer');
	fancyLabels('input, select, textarea');
	contactForm('form.contact-form');
	gaTriggers('.js-track');

	$('.menu-icon').click(function () {
		$('nav').toggleClass('active');
	});

	$('.menu-links a, .btn').click(function () {
		if ($('nav').hasClass('active')) {
			$('nav').removeClass('active');
		}
	});

	$('.js-scroll').click(function (e) {
		var target = $(this.hash);
		if (target.length) {
			e.preventDefault();
			$('html, body').animate({ scrollTop: target.offset().top }, 500);
		}
	});

	var acceptCookie = getCookie("cookie-policy-accepted")
	if (acceptCookie == "true" || acceptCookie == "false") {
		$('.cookie-banner').hide();
	}

});

$(window).on('resize', function () {
	revealFooter('footer');
});

// COOKIE POLICY
// selectors
const acceptBtn = document.querySelector('.js-accepted');
const declineBtn = document.querySelector('.js-declined');
const par = document.querySelector('.js-hide');
const link = document.querySelector('.js-hide-link');
const cookieDiv = document.querySelector('.cookie-banner');
const cookieFlex = document.querySelector('.cookie-flex');
// get items for local storage
// acceptBtn.classList.add(localStorage.getItem('remove-link'));
// declineBtn.classList.add(localStorage.getItem('remove-link'));
// par.classList.add(localStorage.getItem('remove-link'));
// link.classList.add(localStorage.getItem('remove-link'));
// cookieDiv.classList.add(localStorage.getItem('remove-link'));
// cookieFlex.classList.add(localStorage.getItem('js-hide-flex'));

//functions
function acceptCookie(name, value) {
	// set expired date
	let date = new Date();
	date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + date.toUTCString();
	// set cookie policy values
	document.cookie = name + '=' + value + '; ' + expires + '; path=/';
	// set to local storage the class .hide
	// localStorage.setItem('remove-link', 'hide');
}
function declineCookie(name, value) {
	// set expired date
	let date = new Date();
	date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + date.toUTCString();
	// set cookie policy values
	document.cookie = name + '=' + value + '; ' + expires + '; path=/';
	// set to local storage the class .hide
	// localStorage.setItem('remove-link', 'hide');
}

//event listeners
acceptBtn.addEventListener('click', function () {
	//add display none to cookie-banner div
	// cookieDiv.style.display = 'none';
	// store the .hide class to local storage
	// localStorage.setItem('remove-link', 'hide');
	//set cookie policy to true
	acceptCookie('cookie-policy-accepted', true);
	document.querySelector(".cookie-banner").style.display = "none";

});

declineBtn.addEventListener('click', function () {
	//add display none to cookie-banner div
	// cookieDiv.style.display = 'none';
	// store the .hide class to local storage
	// localStorage.setItem('remove-link', 'hide');
	//set cookie policy to false
	acceptCookie('cookie-policy-accepted', false);
	document.querySelector(".cookie-banner").style.display = "none";
});
